'use client';

import AgenciaLandpage from './agencia-landpage';
import AntecipameLandpage from './antecipame-landpage';
import MedsimplesLandpage from './medsimples-landpage';
import { useTenantConfig } from './providers/tenant_provider';

export default function Home() {
  const config = useTenantConfig();

  switch (config?.landpage) {
    case 'antecipame':
      return <AntecipameLandpage />;
    case 'agencia':
      return <AgenciaLandpage />;
    default:
      return <MedsimplesLandpage />;
  }
}
